import { useState, useRef } from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import { func, number } from "prop-types";
import {
  ButtonsWrap,
  GradientButton,
  OutlinedButton,
  TextBlackThin,
} from "base/styled";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import network from "base/network";

const Wrapper = styled.div`
padding: 15px;
  @media (max-width: 768px) {
    p {
      width: auto;
      max-width: fit-content !important;
    }
  }
`;

const Message = styled.div`
  margin-top: 20px;
`;

const Content = styled.div`
  padding: 20px 10px 10px 20px;
`;

const Textarea = styled.textarea`
  border: 1px solid ${props => props.theme.colors.lightGrey};
  font-family: 'Montserrat', sans-serif;
  font-size: .75rem;
  color: ${props => props.theme.colors.lightGrey};
  border-radius: 8px;
  height: 80px;
  width: 100%;
  padding: 10px;
  margin: 0;
`;

export default function Suggest({handleShowModalOff, id, setSuccess}) {
  const { t: rootT } = useTranslation("home");
  const [message, setMessage] = useState('');

  const postSuggest = async () => {
    await network
      .post(
        `/api/user_suggestions/${id}`,
        {
          lead_id: id,
          text: message,
          kind: 'domain',
        },
      )
      .then((response) => {
        console.log(response);
        handleShowModalOff();
        setSuccess(true);
      })
      .catch((error) => console.log(error));
    
  };

  function handleSubmit() {
    postSuggest();
  }

  const captchaRef = useRef(null);

  function handleMessage(e) {
    setMessage(e.target.value);
    console.log(message);
  }

  return (
    <Wrapper>
      <>
        <TextBlackThin style={{ textAlign: "center" }}>
          {rootT("suggest")}
        </TextBlackThin>
        <Content>
          <Message>
            <Textarea value={message} onChange={handleMessage} label="label" />
          </Message>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            ref={captchaRef}
          />
          <ButtonsWrap>
            <OutlinedButton onClick={handleShowModalOff}>
              {rootT("cancel")}
            </OutlinedButton>
            <GradientButton onClick={handleSubmit}>
              {rootT("submit")}
            </GradientButton>
          </ButtonsWrap>
        </Content>
      </>
    </Wrapper>
  );
}

Suggest.propTypes = {
  handleShowModalOff: func, 
  id: number, 
  setSuccess: func
};
